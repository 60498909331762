<template>
  <div style="position: relative">
    <div
      @click="handleBlockClick"
      :style="setBlockStyle()"
      :class="`select-i5__select ${setFocus()}`"
    >
      <div class="select-i5__select_multiple">
        <div :class="type === 'multiple' ? 'select-i5__select_multiple_item' : ''"
             @mousedown="(e) => e.preventDefault()"
             v-for="(item, index) in multipleValues" v-bind:key="`mult_val_${index}`"
        >
          <div @mousedown="(e) => e.preventDefault()"
               class="select-i5__select_multiple_item_text">
            {{ item.name }}
          </div>
          <div @mousedown="(e) => e.preventDefault()">1</div>
        </div>
        <div :class="'select-i5__select_multiple_input'">
          <input
                 :value="inputValue"
                 :style="setInputStyle()"
                 :placeholder="savedValue"
                 :readonly="readonly"
                 @focus="handleFocus"
                 @blur="handleFocusOut"
                 @input="handleInput"/>
          <div class="select-i5__select_arrow">
            <div
              v-if="type === 'select'"
              :class="open ? 'select-i5__select_arrow_icon select-i5__select_arrow_icon_open'
              : 'select-i5__select_arrow_icon'">
              <Arrow />
            </div>
            <div v-if="type === 'search'" class="select-i5__select_arrow_icon">
              <Search  />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="open"
      :id="`${name}_dropdown`"
      :style="{
        width: `${$el.scrollWidth}px`,
        maxHeight: `${dropDownMaxHeight}px`,
      }"
      :class="`select-i5__dropdown ${setOpen()} ${setDirection()}`"
    >
      <div style="height: 100%">
        <div v-for="(item, index) in dropdownValues" v-bind:key="index">
          <div class="select-i5__dropdown_item"
               @mousedown="(e) => e.preventDefault()"
               @click="handleClick(item, index)">
            <div>
              {{item[title]}}
            </div>
            <div v-if="item.active">1</div>
          </div>
        </div>
        <div
          v-show="!dropdownValues || dropdownValues.length === 0"
          class="block block__center block__align-center">
          <img src="@/assets/pictures/select/box.png" class="select-i5__dropdown_empty" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js';

const Arrow = () => import('@/assets/pictures/select/select-arrow.svg');
const Search = () => import('@/assets/pictures/select/search.svg');

export default {
  name: 'I5Select',
  data() {
    return {
      inputValue: '',
      open: false,
      disabledSelect: false,
      readonly: true,
      savedValue: '',
      tempValue: '',
      dropdownValues: [],
      multipleValues: [],
      dropDownDirection: 'bottom', // top
      dropDownMaxHeight: 100,
      dropDownHeight: 100,
      focus: false,
      fuse: null,
    };
  },
  props: {
    name: {
      type: String,
    },
    type: {
      type: String,
      default: 'select',
    },
    values: {
      type: Array,
      default: () => [],
    },
    fontWeight: {
      type: Number,
      default: 400,
    },
    defaultValue: String,
    design: {
      type: String,
      default: 'default',
    },
    title: {
      type: String,
      default: 'title',
    },
    search: Function,
    query: Function, // method, url, key
    disabled: Boolean,
  },
  watch: {
    values: function (newVal) {
      this.dropdownValues = newVal;
    },
    defaultValue: function () {
      this.setDefaultValue();
    },
    dropdownValues: function () {
    },
  },
  methods: {
    handleBlockClick() {
      this.dropdownToggle(this.$el);
      this.open = true;
      if (this.type === 'search') {
        this.inputValue = '';
      }
    },
    handleFocus() {
      if (!this.disabledSelect) {
        this.focus = true;
      }
      if (this.type === 'search' && this.query && this.savedValue) {
        this.query('').then((item) => {
          this.dropdownValues = item;
        });
      }
    },
    openDropdown() {
      if (this.type !== 'search') {
        this.dropdownToggle(this.$el);
        this.open = !this.open;
      }
    },
    handleFocusOut() {
      this.focus = false;
      this.open = false;
      if (this.type === 'search') {
        this.inputValue = this.savedValue;
      }
    },
    setReadOnly() {
      let result = true;
      if (this.type === 'search') {
        result = false;
      }
      return result;
    },
    handleInput(evt) {
      this.inputValue = evt.target.value;
      this.$emit('search', evt.target.value);
      if (this.type === 'search' && this.query) {
        this.query(evt.target.value).then((item) => {
          this.dropdownValues = item;
          this.dropdownToggle(this.$el);
          this.open = true;
        });
      } else {
        const fuseSearch = this.fuse.search(evt.target.value);
        if (evt.target.value) {
          this.dropdownValues = this.values;
          fuseSearch.forEach((item) => {
            this.dropdownValues[item.refIndex].show = true;
          });
        } else {
          this.dropdownValues = this.values;
        }
      }
    },
    setOpen() {
      let result = '';
      if (this.open) {
        result = 'select-i5__dropdown_open';
        this.$nextTick(() => {
          const dropdown = document.getElementById(`${this.name}_dropdown`);
          this.dropDownHeight = dropdown.scrollHeight;
        });
      }
      return result;
    },
    setMultiple() {
      let result = '';
      if (this.type === 'multiple') {
        result = 'select-i5__select_multiple';
      }
      return result;
    },
    dropdownToggle(el) {
      const y = el.getBoundingClientRect().y;
      if (window.innerHeight - y > y) {
        this.dropDownDirection = 'bottom';
        this.dropDownMaxHeight = window.innerHeight - y;
      } else {
        this.dropDownDirection = 'top';
        this.dropDownMaxHeight = y - 105;
      }
    },
    setDirection() {
      return `select-i5__dropdown_${this.dropDownDirection}`;
    },
    handleClick(item, index) {
      if (this.type === 'search') {
        this.inputValue = item[this.title];
        this.savedValue = item[this.title];
        this.$emit('change', item);
        this.open = false;
      } else if (this.type === 'multiple') {
        this.dropdownValues[index].active = !this.dropdownValues[index].active;
        if (!this.dropdownValues[index].active) {
          this.multipleValues.push(item);
        }
        this.$emit('change', this.multipleValues);
      } else {
        this.inputValue = item[this.title];
        this.$emit('change', item);
        this.open = false;
      }
    },
    setFocus() {
      if (this.focus) {
        return 'select-i5__select_focus';
      }
      return '';
    },
    setDefaultValue() {
      if (this.defaultValue) {
        this.savedValue = this.defaultValue;
        if (this.type === 'select') {
          this.inputValue = this.defaultValue;
        } else if (this.type === 'multiple') {
          this.multipleValues = this.defaultValue;
        } else if (this.type === 'search') {
          this.inputValue = this.defaultValue;
        }
      }
    },
    setBlockStyle() {
      let result = {};
      if (this.design === 'default') {
        result = {
          border: '1px solid #5F6368',
        };
      } else if (this.design === 'shadow') {
        result = {
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        };
      } else if (this.design === 'contrast') {
        result = {
          border: '1px solid rgba(189, 49, 34, 0.08)',
        };
      }
      return result;
    },
    setInputStyle() {
      let result = {};
      if (this.design === 'default') {
        result = {
        };
      } else if (this.design === 'contrast') {
        result = {
          backgroundColor: 'rgba(189, 49, 34, 0.08)',
        };
      }
      result.fontWeight = this.fontWeight;
      return result;
    },
  },
  components: {
    Arrow,
    Search,
  },
  mounted() {
    if (this.type === 'search') {
      this.readonly = false;
    }
    if (this.type === 'search' && this.query && this.savedValue) {
      this.query('').then((item) => {
        this.dropdownValues = item;
      });
    }
  },
  created() {
    if (this.values) {
      this.dropdownValues = this.values;
      this.fuse = new Fuse(this.values, {
        keys: [this.title],
      });
      this.fuse.options.threshold = 0.1;
    }
    if (this.defaultValue) {
      this.setDefaultValue();
    }
  },
  destroyed() {
  },
};
</script>
